.menu-principal {
  display: flex;
  align-items: center;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  & > li {
    position: relative;
    &:nth-last-child(1) {
      margin-right: 0;
    }
    &.menu-item-has-children {
      & > a {
        margin-right: 10px;
        position: relative;
        padding-right: 1.1rem;
        &::before {
          content: "";
          position: absolute;
          display: block;
          background: url("../assets/ico/ico-dropdown.png") no-repeat center
            center;
          width: 9px;
          height: 9px;
          background-size: cover;
          right: 2px;
          top: 13px;
          filter: invert(2);
        }
      }
    }
    & > a {
      padding: 0.5rem 1rem;
      text-decoration: none;
      color: $color-white;
      font-weight: 500 !important;
      &:hover {
        color: $color-secondary;
      }
    }
    &.menu-principal--cta {
      margin-left: 1rem;
      a {
        // @extend .button;
        // @extend .button--mint;
        // @extend .button--sm;
        font-weight: 700 !important;
      }
    }
    .sub-menu {
      background-color: $color-white;
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      display: none;
      position: absolute;
      box-shadow: $box-shadow;
      top: 2rem;
      z-index: 3;
      border-radius: 5px;
      min-width: 240px;
      li {
        min-width: 200px;
        a {
          color: $color-grey-2;
          font-size: 0.9rem;
          padding: 0.5rem 1.25rem;
          display: block;
          text-decoration: none;
          &:hover {
            background-color: $color-secondary;
            color: $color-white;
          }
        }
      }
    }
  }
}

.nav-cat {
  background-color: $color-secondary;
  padding: 0.8rem;
  @media screen and (max-width: 767px) {
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 0;
  }
  &__container {
  }
  &__nav {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    justify-content: center;
    @media screen and (max-width: 767px) {
      flex-wrap: nowrap;
      overflow-x: auto;
      justify-content: flex-start;
      padding-bottom: 0.8rem;
    }
    .menu-item {
      @media screen and (max-width: 767px) {
        margin-left: 0.6rem;
      }
      a {
        color: $color-background;
        font-weight: 400;
        padding: 0 1rem;
        display: inline-flex;
        @media screen and (max-width: 767px) {
          display: inline-block;
          background-color: $color-primary;
          padding: 0.3rem 0.8rem;
          border-radius: 50px;
          font-size: $font-size-small;
          white-space: nowrap;
        }
      }
    }
  }
}

.wpml-ls-sidebars-wpml-top {
  padding: 0 !important;
  li.wpml-ls-item {
    a.wpml-ls-link {
      padding: 5px 8px 5px;
      span.wpml-ls-native {
        color: $color-grey-3;
        @media screen and (max-width: 767px) {
          font-size: $font-size-smaller;
        }
        &:hover {
          color: $color-secondary;
        }
      }
    }
    &.wpml-ls-current-language {
      a span {
        color: $color-background !important;
        &:hover {
          color: $color-background;
        }
      }
    }
  }
}

.menu-off-separator {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
}

.menu-off {
  @extend .menu-off-separator;
  &__nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .menu-item {
    @media screen and (min-width: 992px) {
      margin-bottom: $font-size-big;
    }
    margin-bottom: $font-size-smaller;
    a:hover {
      color: $color-white;
    }
    &__item {
      @media screen and (min-width: 992px) {
        font-size: 1.3rem;
      }
      font-size: 1.5rem;
      display: block;
      color: $color-white;
    }
    &__sub {
      font-size: $font-size-small;
      display: block;
      color: $color-grey-4;
    }
  }
}

.offcanvas-dark {
  background-color: $color-grey-1;
  .offcanvas-header {
    color: $color-white;
  }
  .btn-close {
    filter: invert(2) contrast(2);
  }
}

.menu-empresa {
  @extend .menu-off-separator;
  &__nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    display: flex;
  }
  .menu-item {
    font-weight: $font-size-small;
    margin-right: 1rem;
    a {
      font-size: $font-size-small;
      color: $color-secondary !important;
      &:hover {
        color: $color-white;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.menu-contacto {
  // @extend .menu-off-separator;
  padding-top: 0.8rem;
  &__nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    display: flex;
  }
  .menu-item {
    font-weight: $font-size-small;
    margin-right: 1rem;
    a {
      font-size: $font-size-small;
      padding: 0.3rem 1rem;
      background-color: $color-grey-6;
      border-radius: 50px;
      color: $color-grey-2;
      display: inline-block;

      &:hover {
        color: $color-white;
      }
    }
    &--whatsapp {
      a {
        background-color: $color-whatsapp-dark;
        color: $color-white;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.widget--legal {
  @media screen and (max-width: 991px) {
    margin-bottom: 2rem !important;
  }
  .widget__title {
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
  .product-categories,
  .menu {
    list-style: none;
    padding-left: 0rem;
    @media screen and (max-width: 991px) {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      @media screen and (max-width: 991px) {
        margin-right: 6px;
        margin-bottom: 6px;
      }
      a {
        @media screen and (max-width: 991px) {
          padding: 3px 8px;
          background-color: $color-grey-4;
          color: $color-background;
          display: inline-flex;
          font-size: $font-size-small;
          letter-spacing: -0px;
          border-radius: 4px;
        }
      }
    }
    li.current-menu-item {
      a {
        // font-weight: bold;
        @media screen and (max-width: 991px) {
          background-color: $color-grey-1;
        }
      }
    }
  }
}
