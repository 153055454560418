.cta {
  padding: 4rem;
  background: url("http://forescid.com/wp-content/uploads/2022/02/baldana-arroz-fores-cid-800x600.jpg")
    center center no-repeat;
  background-size: cover;
  background-size: 130%;
  color: $color-background;
  border-radius: $border-radius-cta;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    background-size: auto;
    padding: 3rem 1.5rem;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &__pre-title {
    font-size: $header-4;
    @media screen and (max-width: 767px) {
      margin-bottom: 2rem;
    }
  }

  &__title {
    margin-bottom: $space-2;
    font-size: $header-1;
    @media screen and (max-width: 991px) {
      font-size: $header-2;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-2;
    }
  }

  &__actions {
    .boton {
      @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
        text-align: center;
      }
    }
  }
  &__content {
    position: relative;
    @media screen and (min-width: 992px) {
      width: 80%;
    }
  }
}
