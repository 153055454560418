.page,
.post {
  &__entry {
    line-height: 1.7;
    color: $color-grey-2;
    font-weight: $font-weight-normal;
    p,
    ul,
    ol,
    blockquote {
      a {
        color: $color-primary !important;
        // color: $color-grey-1 !important;
        text-decoration: underline !important;
        // text-decoration: underline;
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.6;
      margin-bottom: 1.5rem;
    }
    p,
    ol,
    ul {
      font-size: 1.1rem;
      @media print {
        font-size: 10pt;
      }
    }
    p,
    ol,
    ul,
    blockquote {
      margin-bottom: 1.5rem;
    }

    ul {
      li {
        margin-bottom: 10px;
      }
    }

    h2 strong,
    h2 {
      font-size: 2.2rem;
      color: $color-primary !important;
      font-weight: 700;
      @media screen and (max-width: 575px) {
        font-size: 2rem;
        line-height: 1.3;
      }
      & strong {
        font-weight: 400;
      }
    }
    h3 strong,
    h3 {
      font-size: 1.5rem;
      color: $color-secondary-dark;
      @media screen and (max-width: 575px) {
        font-size: 1.4rem;
        line-height: 1.3;
      }
    }
    strong {
      color: $color-grey-1;
    }
    .wp-block-pullquote {
      padding: 2rem 0 !important;
      text-align: left !important;
      margin-bottom: 0 !important;
    }
    .wp-block-image {
      margin: 2rem 0;

      @media print {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }

      img {
        width: 100%;
        height: auto;
        border-radius: $border-radius !important;
        box-shadow: $box-shadow;
      }
      figcaption {
        text-align: center;
        font-size: $font-size-smaller;
      }
    }
    .wp-block-separator {
      margin: 2rem 0 !important;
      border-bottom: 0 !important;
      border-style: dashed !important;
    }
    .wp-block-gallery {
      margin: 1.8rem 0;
      .blocks-gallery-item {
        margin-bottom: 0;
      }
      img {
        width: 100%;
        height: auto;
      }
      figcaption {
        text-align: center;
      }
    }
    .block-wikiloc {
      iframe {
        width: 100%;
        margin-top: 2rem !important;
        margin-bottom: 2rem;
        box-shadow: $box-shadow;
        border-radius: 0.5rem;
      }
      div {
        display: none;
      }
    }
  }
}

.post-body {
  color: $color-grey-3;
  strong {
    color: $color-grey-1;
  }
  &--max {
    font-size: $font-size-big;
    line-height: 1.6;
  }
  &--light {
    color: rgba(255, 255, 255, 0.8) !important;
    strong {
      color: $color-white !important;
    }
  }
}

.gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1px;
  margin-top: 1px;
  margin-bottom: 1px;
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }
  &__item {
    img {
      width: 100%;
      height: auto;
    }
  }
}
