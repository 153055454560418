.page-title {
  padding: 3rem 0;
  @media screen and (max-width: 767px) {
    padding: 1.5rem 0;
  }
  &--producto {
    padding: 1rem 0;
  }
  &__h {
    font-size: $header-1;
    @media screen and (max-width: 767px) {
      font-size: $header-3;
    }
  }
  &__breadcrumbs {
    // text-transform: uppercase;
    font-size: $font-size-smaller;
    color: $color-grey-4;
    @media screen and (max-width: 767px) {
      overflow-x: auto;
      display: block;
    }
    a {
      color: $color-grey-2;
    }
    @media screen and (min-width: 992px) {
      > span {
        > span {
          > a {
            margin-right: 0.2rem;
          }
          > span {
            a {
              margin-left: 0.2rem;
              margin-right: 0.2rem;
            }
          }
        }
      }
      .breadcrumb_last {
        margin-left: 0.2rem;
      }
    }
  }
}
