.g-historia {
  display: flex;
  gap: 4rem;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    gap: 2rem;
  }
  &__left {
    @media screen and (min-width: 992px) {
      flex: 0 0 40%;
    }
  }

  &__pretitulo {
    font-size: $header-5;
    text-transform: uppercase;
    color: $color-secondary;
  }

  &__titulo {
    font-size: $header-2;
    color: $color-primary;
    font-weight: bold;
  }

  &__picture {
  }

  &__content {
  }
}

.g-calidad {
  display: flex;

  gap: 4rem;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    gap: 2rem;
  }
  &__left {
    @media screen and (min-width: 992px) {
      flex: 0 0 40%;
    }
  }

  &__pretitulo {
    font-size: $header-5;
    text-transform: uppercase;
    color: $color-secondary;
  }

  &__titulo {
    font-size: $header-2;
    color: $color-white;
    font-weight: bold;
    margin-bottom: $space-2;
  }

  &__picture {
    margin-top: 2rem;
  }

  &__content {
    color: $color-background;
  }
}
