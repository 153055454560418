.header {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: $trans;
  width: 100%;
  z-index: 99;
  background-color: $color-grey-1;
  color: $color-white;
  position: sticky;
  top: 0;
  @media screen and (max-width: 1419px) {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
  @media screen and (max-width: 1199px) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &__container {
    display: flex;
    justify-content: flex-start;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
  }

  &__burger {
    color: $color-white;
    display: flex;
    align-items: center;
    margin-right: 2rem;
    font-size: 2rem;
    @media screen and (max-width: 767px) {
      margin-right: 0.8rem;
    }
  }
  &__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 2rem;
    @media screen and (max-width: 1199px) {
      display: none;
    }
  }
  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 2rem;

    .header-logo {
      width: 150px;
      height: auto;
      transition: $trans;
      @media screen and (max-width: 1199px) {
        width: 130px;
      }
      @media screen and (max-width: 767px) {
        width: 100px;
      }
    }
  }
  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: 1199px) {
      margin-left: auto;
    }
  }
  &__right-item {
    margin-left: 0.8rem;
    display: flex;
    align-items: center;
  }
  .bi {
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
    }
  }
}

.circle-container {
  color: $color-white;
  transition: $trans;
  @media screen and (min-width: 768px) {
    background-color: $color-primary;
    width: 39px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-size: 1.2rem;
  }
  &:hover {
    color: $color-white;
    transition: $trans;
  }
}

.cart-totals {
  font-size: 0.7rem;
  margin-left: 0.5rem;
  color: $color-white;
  @media screen and (max-width: 767px) {
    display: none;
  }
  &__price {
  }

  &__items {
  }
}
