.feat {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  &__item {
    display: flex;
    margin-bottom: 4rem;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: 1rem;
    }
    &:nth-child(2n) {
      .feat__content {
        order: -1;
        @media screen and (max-width: 767px) {
          order: initial;
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  > * {
    // border: 1px solid red;
  }

  &__picture {
    flex: 0 0 60%;
    @media screen and (min-width: 768px) and (max-width: 991px) {
      flex: 0 0 40%;
    }
    img {
      width: 100%;
      height: 550px;
      object-fit: cover;
      border-radius: $border-radius;
      @media screen and (min-width: 768px) and (max-width: 991px) {
        height: 300px;
      }
      @media screen and (max-width: 767px) {
        height: 250px;
      }
      @media screen and (max-width: 575px) {
        height: 200px;
      }
    }
  }

  &__content {
    // flex: 0 0 40%;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: 768px) and (max-width: 991px) {
      padding: 2.5rem;
      padding-top: 0;
    }
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }

  &__pretitulo {
    font-size: $header-6;
    color: $color-secondary;
    text-transform: uppercase;
  }

  &__titulo {
    margin-bottom: $space-1;
    font-size: $header-2;
    color: $color-primary;
    font-weight: 600;
    @media screen and (min-width: 768px) and (max-width: 991px) {
      font-size: $header-3;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-3;
    }
  }

  &__descripcion {
    @media screen and (max-width: 767px) {
      p {
        font-size: $font-size-small;
      }
    }
  }

  &__actions {
    margin-top: $space-1;
    @media screen and (max-width: 767px) {
      margin-top: $space-1 / 2;
    }
  }
}
