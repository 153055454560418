.grid-sidebar {
  display: grid;
  grid-template-columns: 3fr 9fr;
  grid-gap: 3rem;
  margin-bottom: $space-1;
  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 0;
    margin-bottom: 0;
  }
}
