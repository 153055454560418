.wpcf7 {
  margin: 0rem 0 2rem;

  .form-group {
    margin-bottom: 1rem;
  }

  .form-label {
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-bottom: 0.2rem !important;
  }

  .form-select,
  .form-control {
    width: 100%;
    padding: 0.575rem 0.75rem;
    font-size: 0.85rem;
    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
  }

  .wpcf7-not-valid-tip {
    font-size: 12px;
    color: red;
  }
}

.wpcf7-acceptance {
  font-size: 0.6rem;
  color: $color-grey-3;
  color: rgba(0, 0, 0, 0.4);

  .wpcf7-list-item {
    // margin-left: 0;
  }
}

.wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.5em 1em;
  border-radius: 8px;
}

.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
  background-color: #398f14;
  color: $color-background;
}

.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
  background-color: #ff0000;
  color: $color-background;
}

.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
  background-color: #ffa500;
}

.wpcf7-acceptance-missig {
  border: 2px solid #ffa500 !important;
  background-color: #ffa500 !important;
}

.qib-container {
  border-radius: 50px;
  overflow: hidden;
  border: 0;
  background-color: $color-white;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  .qib-button {
    font-weight: 400;
    font-size: 1.2rem;
    border: 0 !important;
    background: $color-white !important;
    transition: $trans !important;
    &:hover {
      background-color: $color-grey-5 !important;
      color: $color-grey-1 !important;
      transition: $trans !important;
      cursor: pointer !important;
    }
  }
  .quantity {
    .qty {
      border: 0 !important;
    }
  }
}
