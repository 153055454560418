.footer {
  background-color: $color-secondary;
  color: $color-white;
  &__cols {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: $space-4;
    gap: 2rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      margin-bottom: $space-2;
    }
  }

  &__col {
  }

  &__credits {
    text-align: center;
    small {
      font-size: $font-size-smaller;
      a {
        color: $color-white;
        text-decoration: underline;
      }
    }
  }
}

.logo-footer {
  width: 190px;
  height: auto;
  @media screen and (max-width: 991px) {
    width: 140px;
  }
}

.widget-footer {
  &__title {
    margin-bottom: $space-1;
    font-size: $header-4;
    @media screen and (max-width: 767px) {
      margin-bottom: $space-1 / 2;
      font-weight: $font-weight-bold;
    }
  }
  ul {
    @include reset-ul;
    .menu-item {
      @media screen and (min-width: 768px) {
        margin-bottom: 5px;
      }
      a {
        color: $color-white;
        font-size: $font-size-small;
      }
    }
  }
  .textwidget {
    font-size: $font-size-small;
  }
}

.compres {
  padding: 2rem 0;
  &__container {
  }

  &__content {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    @media screen and (max-width: 575px) {
      flex-direction: column;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    @media screen and (min-width: 768px) and (max-width: 991px) {
      flex: 0 0 40%;
      margin-bottom: 3rem;
    }
    @media screen and (max-width: 575px) {
      margin-bottom: 1rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icono {
    margin-right: 1.5rem;
    @media screen and (max-width: 575px) {
      flex: 0 0 10%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 50px;
      height: auto;
      filter: grayscale(1) contrast(1);
    }
  }

  &__titulo {
    font-weight: 500;
    font-size: $font-size-small;
    font-weight: bold;
    color: $color-dark;
    span {
      font-weight: normal;
      display: block;
      color: $color-grey-3;
    }
  }
}
