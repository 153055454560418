.p404 {
  display: flex;
  gap: 3rem;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    gap: $font-size;
  }
  &__left {
    img {
      width: 180px;
      height: auto;
      @media screen and (max-width: 991px) {
        width: 80px;
      }
    }
  }

  &__content {
  }

  &__titulo {
    margin-top: 0;
    margin-bottom: $font-size-big;
    font-size: $header-3;
    color: $color-primary;
  }

  &__subtitulo {
    margin-top: 0;
    margin-bottom: $font-size-big * 2;
    font-size: $header-1;
    @media screen and (max-width: 991px) {
      font-size: $header-3;
      line-height: 1.5;
    }
    strong {
      color: $color-secondary;
      font-weight: normal;
    }
    mark {
      background-color: yellow;
      padding: 0em 0.2em;
      border-radius: 3px;
    }
  }

  &__actions {
  }
}
