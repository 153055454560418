.g-garantias {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  margin-bottom: 4rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
  &__item {
  }

  &__pic {
    margin-bottom: 2rem;
    img {
      width: auto;
      height: 90px;
      mix-blend-mode: multiply;
    }
  }

  &__content {
  }

  &__titlulo {
    font-size: $header-3;
    margin-bottom: $space-1;
    color: $color-grey-1;
    color: $color-secondary;
  }

  &__descripcion {
    p {
      font-size: $font-size;
      color: $color-grey-3;
      strong {
        color: $color-grey-1;
      }
    }
  }
}

.g-horeca {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  color: $color-white;
  margin-bottom: $space-4;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  &__left {
    img {
      border-radius: $border-radius;
      max-width: 100%;
    }
  }

  &__right {
  }

  &__titulo {
    margin-bottom: $space-2;
    font-size: $header-2;
  }

  &__content {
    p {
      color: $color-grey-5;
      strong {
        color: $color-white;
      }
    }
  }
}

.g-dist {
  color: $color-white;
  @media screen and (min-width: 992px) {
    width: 60%;
  }
  &__titulo {
    margin-bottom: $space-1;
    font-size: $header-2;
  }

  &__descripcion {
    color: $color-grey-4;
    margin-bottom: $space-1;
    strong {
      color: $color-white;
    }
  }
  &__canales {
    display: flex;
    flex-wrap: wrap;
  }
  &__canal {
    background-color: $color-secondary;
    padding: 5px 1rem;
    border-radius: $border-radius;
    margin-right: 5px;
    margin-bottom: 5px;
    i {
      color: $color-primary-dark;
    }
  }
}
