.contactos {
  list-style: none;
  padding-left: 3rem;
  margin-left: 3rem;
  border-left: 1px solid $color-grey-3;
  @media (min-width: 768px) and (max-width: 991px) {
    margin-left: 1rem;
    padding-left: 1rem;
  }
  @media (max-width: 575px) {
    padding-left: 0;
    margin-left: 0;
    border: 0;
  }
  li {
    margin-bottom: 2rem;
  }
  li strong {
    text-transform: uppercase;
    display: block;
    color: $color-grey-1;
    margin-bottom: 4px;
  }
}

.g-contacto {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
