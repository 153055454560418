body {
  // background-color: $color-white;
  font-size: $font-size;
  color: $color-text-body;
  font-weight: $font-weight-normal;
  font-family: $typo-primary;
}

a {
  color: $color-primary;
  transition: $trans;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    transition: $trans;
  }
}
